// Project single

.project {
  color: $dark-grey;
}

.project__skills-list {
  padding: 0;
  list-style: none;
  @include font-size(10px);
  @include margin-leader(0.5);
  padding-left: $base-spacing-unit;
  padding-right: $base-spacing-unit;
  margin: ($base-spacing-unit/5) 0 0;

  width: 100%;
  text-align: left;
  text-transform: uppercase;
  color: $color-primary;

  @include breakpoint-between($bp-sm, $bp-xl) {
    @include margin-trailer(0.5);
  }

  @include breakpoint($bp-sm) {
    @include margin-leader(1);
    @include margin-trailer(2);
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.project__skills-item {
  @include space(1);
  @include margin-leader(0.75);
  @include margin-trailer(0.75);

  &:first-of-type {
    @include margin-leader(0);
  }

  &:last-of-type {
    @include margin-trailer(0);
  }

  @include breakpoint($bp-sm) {
    margin: 0;
    font-weight: 700;
    @include space(.5);
  }
}

.project__description,
.project__description-after {
  @include font-size(18px);
  @include margin-trailer(0.75);
  line-height: 1.75;
  text-align: left;
  color: $color-primary;
}

.project__description-after {
  @include margin-trailer(1);
}

.project__link {
  width: 100%;
  text-align: right;

  .arrow-link {
    @include font-size(10px);

    .arrow-link__svg {
      width: $base-spacing-unit;
    }
  }
}

.project__window {
  opacity: 0;
  transition: all 0.25s ease;
  padding: $base-spacing-unit * 2;

  .media-loaded & {
    opacity: 1;
  }

  &:not(img) {
    .media-loaded & {
      cursor: pointer;
    }
  }

  @include breakpoint-min($bp-xl) {
    padding: 64px;
  }
}

.project__window-inner {
  height: 0;
  color: $white;
  overflow: hidden;
  position: relative;
  padding-top: 56.5%;
  background: $color-tertiary;
  transition: opacity 0.25s ease;

  .no-touch-supported & {
    &:hover {
      cursor: none;

      .project__featured-image {
        opacity: 0.5;
        transform: scale(1.1);
        filter: grayscale(100%);
      }
    }
  }
}

.project__featured-image {
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  will-change: scale;
  transform-origin: top center;
  transition: all $transition-custom;
}

.project__popup-trigger {
  margin: 0;
  padding: 0;
  border: 0;

  width: 100%;
  height: 100%;
  display: block;
  background: transparent;

  &:focus {
    outline: 0;
  }

  .project__url-sugar {
    top: -($base-spacing-unit * 2);
    left: -($base-spacing-unit * 2);

    @include breakpoint-min($bp-xl) {
      top: -64px;
      left: -64px;
    }
  }

  &:hover {
    .project__url-sugar {
      opacity: 1;
    }

    .project__url-text {
      animation: rotation-alt 20s infinite linear;
    }
  }
}

.product__comparison-grid {
  position: relative;

  &:before {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include z-index(0, absolute);
    background-image: url('data:image/svg+xml, <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 65.7 32.7"><path fill="%234252d9" d="M0 14.4h61.3v4H0z" /><path fill="%234252d9" d="M48.1 32.7l-2.8-2.9 14.5-13.4L45.3 2.9 48.1 0l17.6 16.4z"/></svg>');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 80px auto;
  }
}

.project__intro {
  p {
    padding-left: $base-spacing-unit;
    padding-right: $base-spacing-unit;

    @include breakpoint-min($bp-xl) {
      padding-left: 0;
      padding-right: 0;
    }

    &.project__link {
      padding: 0 ($base-spacing-unit * 2);

      @include breakpoint-min($bp-xl) {
        padding: 0 64px;
      }
    }
  }

  +.project__panel {
    @include breakpoint-min($main-breakpoint) {
      @include margin-trailer(10);
    }
  }

  .media-wrap {
    @include margin-leader(2);
    @include margin-trailer(0);

    @include breakpoint-min($main-breakpoint) {
      @include margin-leader(4);
      @include margin-trailer(2);
    }

    video {
      opacity: 0;
      transition: all 0.25s ease;

      .media-loaded & {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
}

.project__panel {
  &.alignment--left {
    text-align: left;
  }
}

.project__images {
  background: $lighter-grey;

  @include breakpoint($main-breakpoint) {
    .project__images-shifter {
      @include padding-leader(2);
    }
  }

  @include breakpoint-min($main-breakpoint) {
    .project__images-shifter {
      @include margin-leader(-7);
      @include margin-trailer(3);

      >.container {
        position: relative;
      }
    }
  }
}

.project__images {
  @include breakpoint($main-breakpoint) {
    padding: ($base-spacing-unit * 2) 0;
  }

  &.pr-01 {
    .project__images-grid {
      @include breakpoint-min($main-breakpoint) {
        .grid__item {
          &:nth-child(2) {
            display: flex;
            align-items: flex-end;

            .floatyParent {
              &:first-of-type {
                width: 100%;
                @include margin-trailer(-3);
              }

              &:last-of-type {
                @include margin-leader(2);

                top: 0;
                right: 0;
                width: 320px;
                max-width: 100%;
                position: absolute;
              }
            }
          }
        }
      }
    }
  }

  &.pr-03 {
    .project__images-grid {
      @include breakpoint-min($main-breakpoint) {
        .grid__item {
          &:nth-child(5) {
            .floatyParent {
              @include margin-leader(2);
              @include margin-trailer(-8);
            }
          }

          &:nth-child(6) {
            .floatyParent {
              z-index: 20;
            }

            img {
              @include margin-leader(1);
              margin-left: -($base-spacing-unit * 2);
            }
          }

          &:nth-child(7) {
            .floatyParent {
              z-index: 0;
            }

            .floatyChild {
              @include z-index(0, absolute);
              top: ($base-spacing-unit * 4);
              right: ($base-spacing-unit * 4);
              width: 115%;
            }
          }
        }
      }
    }
  }

  &.pr-04 {
    .project__images-shifter {
      @include margin-trailer(0);
    }
  }

  &.pr-06 {

    @include breakpoint-min($main-breakpoint) {
      @include margin-leader(-6);
      @include margin-trailer(2);

      .project__images-grid {
        .article__title {
          @include margin-leader(8);
        }

        .grid {
          @include breakpoint-min($main-breakpoint) {
            max-width: 90%;
          }
        }
      }
    }
  }

  &.pr-07 {
    .project__images-grid {
      @include breakpoint-min($main-breakpoint) {
        .grid__item {
          &:nth-child(2) {
            .floatyParent {
              @include margin-leader(10);
            }
          }
        }
      }
    }
  }
}

.project__panel-after {
  @include breakpoint($main-breakpoint) {
    @include padding-leader(2);

    img {
      @include margin-trailer(2);
    }
  }
}

.top-container {
  @include z-index(1, relative);

  .top {
    width: 125%;
    max-width: 125%;
  }
}

// Popups
.project__popup {
  @include z-index(-1, fixed);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.25s ease;
  background-color: rgba(0, 0, 0, 0.75);
}

.popup__inner {
  @extend .container;
  @extend .container--med;
  @extend .container--flush;
  height: 0;
  width: 1200px;
  top: 50%;
  left: 50%;
  margin: auto;
  position: absolute;
  background: $white;
  padding-top: 75vh;
  overflow-y: scroll;
  transform: translate(-50%, -50%);
  max-width: calc(100% - (#{$base-spacing-unit} * 2));
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 60px 0px;
}

.popup__image {
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
}

body.popOpen-true {
  width: 100vw;
  height: 100vh;
  position: fixed;

  .project__popup-trigger {
    cursor: initial;
  }

  .project__popup {
    @include z-index(500, fixed);
    opacity: 1;
    pointer-events: all;
    visibility: visible;
    cursor: crosshair;
  }

  .project__url-sugar {
    opacity: 0 !important;
  }
}
