// Mastheads

.masthead {
  position: relative;
  padding: ($section-spacing-unit/2) 0;

  // Base styling to ensure text is visible if an image isn't set.
  color: $white;
  display: flex;
  text-align: left;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  background-color: $black;

  @include breakpoint-min($main-breakpoint) {
    min-height: $masthead-height;
  }

  &.full {
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: $section-spacing-unit 0;

    @include breakpoint-min($main-breakpoint) {
      min-height: $masthead-height-full;
    }
  }

  &.thin {
    .masthead__title {
      @include space(1);
      @include font-size(18px);
      @include margin-trailer(0.5);
      position: relative;
      text-transform: uppercase;
      font-family: $base-font-family;
      padding-left: $base-spacing-unit * 4;

      &:before {
        content: "";
        top: 10px;
        left: 0px;
        height: 2px;
        width: 60px;
        display: block;
        position: absolute;
        background: $white;
        transform-origin: center right;
      }
    }

    .masthead__description {
      @include font-size(32px);
      @include margin-trailer(0);
      font-family: $heading-font-family;

      @include breakpoint-min($bp-xl) {
        @include font-size(72px);
      }
    }

    .masthead__background:before {
      @include z-index(1, absolute);
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      background: rgba(0, 0, 0, 0.25);
    }
  }
}

.masthead__foreground {
  width: 100%;
  @include padding-leader(4);
  @include z-index(40, relative);

  @include breakpoint-min($main-breakpoint) {
    @include padding-leader(0);
  }

  small {
    @include space(1);
    text-transform: uppercase;
  }
}

.masthead__background {
  @extend %full-cover;
  @include z-index(20, absolute);
  overflow: hidden;
  transition: opacity 1.5s ease 0.5s;
}

.masthead__title {
  line-height: 1;

  @include breakpoint-min($bp-xl) {
    @include font-size(150px);
    @include margin-trailer(2);
    line-height: 0.933334;
  }

  @include breakpoint-between($bp-md, $bp-xl) {
    @include font-size(80px);
  }
}

.masthead__content {
  @include breakpoint-min($main-breakpoint) {
    @include font-size(40px);
  }
}

.masthead.full,
.masthead.border {
  .masthead__paragraph {
    @include limit-width(540px);
  }

  .masthead__background {
    transition-duration: 2.5s;

    &:before,
    &:after {
      content: "";
      width: 200%;
      height: 200%;
      display: block;
      @include z-index(1, absolute);

      @include breakpoint-min($main-breakpoint) {
        width: 100vw;
        height: 200vh;
      }
    }

    &:before {
      top: -50%;
      left: -33%;
      background: transparent url(/images/gradients1.png) 0 0 no-repeat;
      background-size: 100% 100%;
      animation: rotation 28s infinite linear;
    }

    &:after {
      bottom: -50%;
      right: -33%;
      background: transparent url(/images/gradients2.png) 100% 100% no-repeat;
      background-size: 100% 100%;
      animation: rotation-reversed 32s infinite linear;
    }
  }
}
