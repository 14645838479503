/*

  Mixins

  Global mixins

*/

// Add a uniform top & bottom spacing to an element (padding or margin)
@mixin margin-leader($proportion: 1) {
  margin-top: $base-spacing-unit * $proportion;
}

@mixin margin-trailer($proportion: 1) {
  margin-bottom: $base-spacing-unit * $proportion;
}

@mixin padding-leader($proportion: 1) {
  padding-top: $base-spacing-unit * $proportion;
}

@mixin padding-trailer($proportion: 1) {
  padding-bottom: $base-spacing-unit * $proportion;
}

// Use REMs with a pixel fallback for font sizing.
@mixin font-size($font-size, $line-height: false) {
  font-size: $font-size;
  font-size: $font-size / $base-font-size * 1rem;

  @if $line-height {
    line-height: $line-height / $font-size;
  }
}

// Media Query Mixin
// Use to create a breakpoint, ideally using predefined breakpoint variables
// For each project, you can create additional breakpoints if required
@mixin breakpoint($media-query) {
  @media only screen and (max-width: $media-query) {
    @content;
  }
}

// Make sure you are using a variable for this breakpoint
@mixin breakpoint-min($media-query) {
  @media only screen and (min-width: $media-query + 1px) {
    @content;
  }
}

@mixin breakpoint-between($min, $max) {
  @media only screen and (min-width: $min + 1px) and (max-width: $max) {
    @content;
  }
}

// Hide elements from all users.//
// Used for elements which should not be immediately displayed to any user. An
// example would be a collapsible fieldset that will be expanded with a click
// from a user. The effect of this class can be toggled with the jQuery show()
// and hide() functions.
@mixin element-hidden {
  display: none;
}

// Z-index mixin
// Pass in an integer from 1-10, the output will be multiplied by 100.
// You can pass the element's positioning as a second parameter, defaults to 'relative'
@mixin z-index($z-index, $position: relative) {
  position: $position;
  z-index: $z-index * 100;
}

// Hides text in an element by squishing the text into oblivion.
// Use this if you need to hide text contained in an inline element but still have it read by a screen reader.
@mixin squish-text {
  color: transparent;
  font: 0/0 serif;
  text-shadow: none;
}

// Limits the text of a block element to a single line,
// cropping the overflow and adding an elipsis
@mixin shorten-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Limits the text width of an element, good for
// wrapping text before it reaches the container edges.
// Use auto for $margin to center blocks.
@mixin limit-width($width, $margin: auto) {
  margin-left: $margin;
  margin-right: $margin;
  max-width: $width;
  width: 100%;
}

// Used for information required for screen-reader users to understand and use
// the site where visual display is undesirable. Information provided in this
// manner should be kept concise, to avoid unnecessary burden on the user.
// "!important" is used to prevent unintentional overrides.
@mixin element-invisible {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}

// Reverts 'element-invisible'.
@mixin element-visible {
  clip: inherit;
  height: inherit;
  overflow: inherit;
  position: inherit !important;
}

// The .element-focusable class extends the .element-invisible class to allow
// the element to be focusable when navigated to via the keyboard.
@mixin element-focusable {

  &:active,
  &:focus {
    clip: auto;
    height: auto;
    overflow: visible;
    position: static !important;
  }
}

// Style form placeholder text consistently between browsers
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::placeholder {
    @content;
  }
}

// Consistent letter-spacing
@mixin space($space) {
  letter-spacing: #{$space}+'px';
}
