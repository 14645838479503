// Animation related styling

@keyframes button-blend {
    from {
        background: #9b5ac3;
    }

    to {
        background: #69d6e6;
    }
}

@keyframes button-blend2 {
    from {
        background: #f23173;
    }

    to {
        background: #fea338;
    }
}

@keyframes dash {
    to {
        stroke-dashoffset: -90%;
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

@keyframes rotation-reversed {
    from {
        transform: rotate(-0deg);
    }

    to {
        transform: rotate(-359deg);
    }
}

@keyframes rotation-alt {
    from {
        transform: rotate(45deg);
    }

    to {
        transform: rotate(404deg);
    }
}

// CSS Tied to React Transition Groups
.fade-enter {
    opacity: 0;
    transition: opacity 1000ms;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 1000ms;
}

.fade-exit {
    opacity: 1;
    transition: opacity 1000ms;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 1000ms;
}
