// Footer

.site-footer {
  @include z-index(5, relative);

  color: $dark-grey;
  background: $black;
  text-align: center;
  text-transform: uppercase;
  padding: 0 0 $section-spacing-unit;

  small {
    @include space(1);
    @include font-size(8px);
    @include margin-trailer(0);

    .em-heart {
      width: ($base-spacing-unit*.75);
      height: ($base-spacing-unit*.75);
    }

    @include breakpoint-min($main-breakpoint) {
      @include font-size(12px);

      .em-heart {
        width: ($base-spacing-unit);
        height: ($base-spacing-unit);
      }
    }
  }

  a {
    color: $dark-grey;

    &:hover {
      color: $grey;
    }
  }
}

.contact-box {
  color: $white;
  overflow: hidden;
  position: relative;
  padding: $base-spacing-unit;

  @include breakpoint-min($main-breakpoint) {
    padding: $base-spacing-unit * 2;
  }

  &:before,
  &:after {
    @include z-index(0, absolute);
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: rgb(90, 177, 221);
    background: linear-gradient(90deg,
        rgba(90, 177, 221, 1) 0%,
        rgba(78, 85, 207, 1) 25%,
        rgba(83, 74, 209, 1) 50%,
        rgba(156, 91, 199, 1) 75%,
        rgba(126, 68, 152, 1) 100%);
  }

  &:after {
    mix-blend-mode: overlay;
    background: rgb(90, 177, 221);
    background: linear-gradient(90deg,
        rgba(90, 177, 221, 0) 0%,
        rgba(87, 130, 215, 0.604079131652661) 20%,
        rgba(59, 67, 197, 1) 48%,
        rgba(65, 67, 193, 1) 52%,
        rgba(97, 68, 172, 0.6012780112044818) 80%,
        rgba(126, 68, 152, 0) 100%);
  }
}

.contact-box__container {
  @include z-index(10, relative);
}

.contact-box__paragraph {
  @include space(1);
  @include font-size(12px);
  @include margin-trailer(0.5);

  @include breakpoint-min($main-breakpoint) {
    @include font-size(18px);
  }
}

.contact-box__headline {
  @include margin-trailer(0);

  @include breakpoint($main-breakpoint) {
    @include font-size(18px, $h6-line-height);
  }

  text-transform: lowercase;

  i,
  strong,
  span {
    display: inline-block;
    vertical-align: middle;
  }
}

.contact-box__link {
  display: block;
  transform: translateY(-50%);
  @include limit-width(800px, auto);

  &:hover {
    .contact-box__headline {
      color: $color-secondary;
    }

    .contact-box:after {
      animation: button-blend 2s ease-in-out infinite alternate;
    }
  }
}
