/*

  Variables

*/

// BREAKPOINTS

$bp-xs: 480px;
$bp-sm: 640px;
$bp-md: 800px;
$bp-lg: 1067px;
$bp-xl: 1200px;
$bp-xxl: 1440px;

$bp-array: '{"xs": "#{$bp-xs}", "sm": "#{$bp-sm}", "md": "#{$bp-md}", "lg": "#{$bp-lg}" ,"xl": "#{$bp-xl}" , "xxl": "#{$bp-xxl}"}';

$main-breakpoint: $bp-lg;

/* -------------------------------------- */

// COLORS
$lighter-grey: #f8f8f8;
$light-grey: #ebe8e1;
$grey: #a6a6a6;
$dark-grey: #555555;
$darker-grey: #2a2f33;

$white: #fff;
$black: #000;

$color-primary: #4252d9; // Blue
$color-secondary: #f8d752; // Yellow
$color-tertiary: #d070eb; // ..
$color-quaternary: #85b6e7; // ..
$color-quinary: #75c69a; // ..
$color-senary: #ffac33; // ..
// $color-septenary: #576ab0; // ..

$btn-primary: #f12e74;
$btn-secondary: #fda139;

$color-positive: #0f0;
$color-warning: #ff0;
$color-negative: #f00;

$body-color: $dark-grey;
$brand-color: $color-primary;
$headings-color: $body-color; // or could be brand-color or whatever, but make sure you are using a variable

$hero-overlay-opacity: 0.4;
$hero-bg-color: $dark-grey;
$hero-overlay-color: $black;
$hero-font-color: $white;

// social media colours

$color-twitter: #00aced;
$color-facebook: #3b5998;
$color-googleplus: #dd4b39;
$color-pinterest: #cb2027;
$color-linkedin: #007bb6;
$color-youtube: #b00;
$color-vimeo: #1ab7ea;
$color-instagram: #bc2a8d;
$color-flickr: #ff0084;
$color-yahoo: #7b0099;

/* -------------------------------------- */

// TYPOGRAPHY

$base-font-family: soleil, arial, sans-serif;
$heading-font-family: alverata-irregular, times, serif;

// Font Sizes
// Currently using 'Major Third' scale (1.25)
$base-font-size: 16px;
$base-line-height: 20px;

$h1-font-size: 60px;
$h1-line-height: 75px;

$h2-font-size: 48px;
$h2-line-height: 60px;

$h3-font-size: 32px;
$h3-line-height: 40px;

$h4-font-size: 28px;
$h4-line-height: 34px;

$h5-font-size: 24px;
$h5-line-height: 32px;

$h6-font-size: 20px;
$h6-line-height: 18px;

$hero-font-size: 80px;
$hero-line-height: 100px;

$small-font-size: 14px;

$nav-font-size: $base-font-size;
$nav-line-height: $base-line-height;

$font-weight-thin: 400;
$font-weight-base: 500;
$font-weight-thick: 700;

$base-spacing: 0.1em;

/* -------------------------------------- */

// SPACING
// Global spacing and measurement variables

// Set consistent vertical and horizontal spacing units.
// $base-spacing-unit        : $base-line-height/$base-font-size * 1rem;
$base-spacing-unit: 20px;

$vert-spacing-unit: $base-spacing-unit;
$horz-spacing-unit: $base-spacing-unit;

$base-border-radius: ($base-spacing-unit / 4);
$base-border: 2px solid $color-primary;

$grid-gutter: $base-spacing-unit;
$fluid-grid-gutter: 5%;

$container-pad: $base-spacing-unit;
$container-large-max: 1700px;
$container-max: 1400px;
$container-med-max: 1100px;
$container-small-max: 800px;
$container-xs-max: 500px;

$btn-line-height: $base-line-height/$base-font-size;
$btn-small-padding: ($base-spacing-unit / 4);
$btn-default-padding: ($base-spacing-unit / 2);
$btn-large-padding: $base-spacing-unit;

$button-toggle-padding: 0.5em;
$button-toggle-width: 2.5rem;
$icon-bar-width: 25px; // Icon Bar Dimensions are better off in fixed pixel sizing
$icon-bar-height: 3px; //
$icon-bar-spacing: $icon-bar-height;

$label-margin: 0.5em;
$form-control-padding: 5px;
$base-input-height: 2.5rem;

$offcanvas-width: 250px;

$island-padding-y: $base-spacing-unit * 2;
$island-padding-x: $base-spacing-unit * 2;

$section-spacing-unit: $base-spacing-unit * 4;

$logo-width: 4rem;

$z-index-base: 10;

$header-height--desktop: 120px;
$header-height--mobile: 60px;

$masthead-height: 450px;
$masthead-height-full: 100vh;

/* -------------------------------------- */

// ANIMATION

$transition-time-slow: 0.6s;
$transition-time-fast: 0.15s;
$transition-time-default: 0.3s;
$transition-timing-default: ease-out;
$transition-slow: $transition-time-slow $transition-timing-default;
$transition-default: $transition-time-default $transition-timing-default;
$transition-fast: $transition-time-fast $transition-timing-default;
$transition-custom: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
$transition-custom-slow: 0.75s cubic-bezier(0.19, 1, 0.22, 1);
