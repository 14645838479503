/*

    Container

*/

.container,
%container {
  @extend %cf;
  width: 100%;
  margin: 0 auto;
  max-width: $container-max;
  padding-left: $container-pad;
  padding-right: $container-pad;

  &.gutter--wide {
    padding-left: $container-pad * 2;
    padding-right: $container-pad * 2;
  }
}

.container--large {
  max-width: $container-large-max;
}
.container--med {
  max-width: $container-med-max;
}
.container--small {
  max-width: $container-small-max;
}
.container--xs {
  max-width: $container-xs-max;
}
.container--flush {
  padding-left: 0;
  padding-right: 0;
}
