// Main

.site-content {
  color: $color-primary;

  &.project {
    overflow-x: hidden;
    padding-bottom: ($base-spacing-unit * 3.9);
  }
}
