.arrow-link {
  @include space(1);
  @include font-size(18px);
  @include margin-trailer(2);

  display: inline-block;
  color: $color-primary;
  text-transform: uppercase;

  >span {
    display: inline-block;
  }

  .home & {
    @include margin-trailer(2);

    @include breakpoint-min($main-breakpoint) {
      @include margin-trailer(4);
    }
  }

  &.color--gray {
    color: $grey;
  }

  &:hover {
    color: $color-secondary;

    .arrow-link__svg {
      margin-left: $base-spacing-unit;
    }
  }

  .arrow-link__svg {
    width: 30px;
    margin-left: ($base-spacing-unit/2);
    transition: margin $transition-default;
  }

  &.prevLink--true {
    .arrow-link__svg {
      margin-left: 0;
      margin-right: ($base-spacing-unit/2);
      transform: rotate(180deg);
    }

    &:hover {
      .arrow-link__svg {
        margin-left: 0;
        margin-right: $base-spacing-unit;
      }
    }
  }

  .project__navigation & {
    @include margin-trailer(0);

    @include breakpoint($main-breakpoint) {
      @include font-size(10px);
      @include margin-leader(1);
      @include margin-trailer(1);

      .arrow-link__svg {
        width: ($base-spacing-unit * 0.75);
      }
    }
  }

  .arrow-wrap & {
    @include font-size(10px);
    @include margin-trailer(0);

    .arrow-link__svg {
      width: ($base-spacing-unit * 0.75);
    }

    .project__intro & {
      @include margin-leader(-1);
      margin-right: ($base-spacing-unit * 2);

      @include breakpoint-min($bp-xl) {
        margin-right: 140px;
        @include margin-leader(-4);
      }
    }
  }
}

.arrow-wrap {
  @include z-index(20, relative);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: ($base-spacing-unit) 0;
}

img+.arrow-wrap {
  @include margin-leader(1.5);
}
