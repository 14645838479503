// Project preview

.project--preview {
  position: relative;
  transition: all $transition-custom;

  .project__container--outer {
    padding: $base-spacing-unit;

    @include breakpoint-min($bp-xl) {
      padding: 64px;
    }
  }

  .project__container--inner {
    position: relative;
  }

  .project__background,
  .project__foreground {
    width: 1150px;
    max-width: 100%;
    margin: 0 auto;
  }

  .project__background {
    z-index: 0;
    height: 0;
    overflow: hidden;
    max-height: 650px;
    padding-top: 56.5%;
    position: relative;
    background: $color-tertiary;
    transition: all $transition-custom;

    .background-layer {
      will-change: scale;
      @include z-index(0, absolute);
      top: -1%;
      left: -1%;
      opacity: 1;
      width: 102%;
      height: 102%;
      transition: all $transition-custom;
    }
  }

  &.project--featured {
    &:first-of-type {

      // margin-bottom: auto;
      @include breakpoint-min($bp-xl) {
        @include padding-leader(0);
        @include margin-leader(0);
      }
    }

    .project__background {
      padding-top: 47%;
    }
  }

  .project__foreground {
    text-align: left;
    position: relative;
    margin-top: -($base-spacing-unit * 2);

    @include breakpoint-min($bp-xl) {
      @include z-index(20, absolute);
      @include padding-leader(0.5);
      top: auto;
      left: 0;
      right: 0;
      bottom: 0;
      height: 115px;
      text-align: left;
      transform: translateY(50%);
    }
  }

  .project__name {
    @include space(2);
    @include font-size(14px);
    @include margin-trailer(1);
    color: $white;
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    font-family: $base-font-family;
    padding-left: $base-spacing-unit;
    padding-right: $base-spacing-unit;

    @include breakpoint-min($bp-sm) {
      @include font-size(15px);
      padding-left: $base-spacing-unit * 5;
      padding-right: $base-spacing-unit * 5;


      &:before,
      &:after {
        content: "";
        top: 10px;
        left: 20px;
        height: 2px;
        width: 60px;
        display: block;
        position: absolute;
        background: $white;
        transition: all $transition-custom;
        transition-delay: 750ms;
        transform-origin: center right;
      }

      &:after {
        width: 0;
        top: 25px;
        left: 100px;
        transition: all $transition-custom;
        transform-origin: center left;
        transition-delay: 0s;
      }
    }

    @include breakpoint-min($bp-xxl) {
      @include font-size(18px);
    }
  }

  .project__tagline {
    @include margin-trailer(0);
    color: $color-primary;
    font-size: $h4-font-size;
    padding-left: $base-spacing-unit;
    padding-right: $base-spacing-unit;

    @include breakpoint-min($bp-xl) {
      font-size: $h3-font-size;
      padding-left: $base-spacing-unit * 2;
      padding-right: $base-spacing-unit * 2;
    }
  }

  &.project.project--featured .project__tagline {
    @include breakpoint-min($bp-xl) {
      font-size: $h2-font-size;
      max-width: calc(100% - 140px);
    }
  }

  .project__skills-list {
    @extend %plain-list;
    @include font-size(10px);

    right: 0;
    top: 100%;
    width: 100%;
    display: none;
    position: absolute;
    color: $color-primary;
    text-transform: uppercase;
    transform: translateY(-50%);

    @include breakpoint-min($main-breakpoint) {
      width: 140px;
      padding-left: ($base-spacing-unit * 2);
      padding-right: ($base-spacing-unit * 2);
    }
  }

  .project__skills-item {
    @include space(1);
    @include margin-leader(0.75);
    @include margin-trailer(0.75);

    &:first-of-type {
      @include margin-leader(0);
    }

    &:last-of-type {
      @include margin-trailer(0);
    }
  }

  &.project.project--featured .project__skills-list {
    @include breakpoint-min($bp-xl) {
      display: block;
    }
  }
}

.project__url-sugar {
  .no-touch-supported & {
    display: block;
  }

  @include z-index(1000, absolute);
  pointer-events: none;
  display: none;
  opacity: 0;
  top: 0;
  left: 0;
  transition: opacity 100ms ease;

  @include breakpoint-min($bp-xl) {
    top: -64px;
    left: -64px;
  }

  .project__url-text,
  .project__url-arrow,
  .project__url-magnify {
    top: -50px;
    left: -50px;
    width: 100px;
    height: 100px;
    position: absolute;
    display: inline-block;
    transform-origin: 50% 50%;
  }

  .project__url-arrow,
  .project__url-magnify {
    svg {
      transform: translate(25%, 25%);
      transform-origin: 25% 25%;
      stroke: $white;
      stroke-linecap: round;
      stroke-width: 0.5px;

      path {
        fill: $white;
        transition: all $transition-fast;
      }
    }
  }

  .project__url-arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      transform: translate(0, 0);
      stroke-width: 0;
      width: 50%;
    }
  }

  .project__url-magnify {
    svg {
      transform: translate(17.5%, 62.5%);
    }
  }

  .project__url-text {
    @include font-size(12px);
    color: $white;
    text-transform: uppercase;
    transform: rotate(45deg);
    animation: rotation-alt 20s infinite linear;

    span {
      position: absolute;
    }

    >span {
      width: 100%;
      height: 100%;
      display: block;
      position: relative;
      transform: translate(0px, -15px);
    }
  }
}

.project__url {
  display: block;
  overflow: hidden;

  .no-touch-supported & {
    &:hover {
      cursor: none;
      color: $color-primary;

      .background-layer {
        opacity: 0.5;
        transform: scale(1.1);
        filter: grayscale(100%);
      }

      .project__url-text {
        animation: rotation-alt 20s infinite linear;
      }

      @include breakpoint-min($bp-xl) {
        .project__name {
          &:before {
            width: 0;
            left: 80px;
            transition-delay: 0ms;
          }

          &:after {
            left: 100px;
            transition-delay: 750ms;
            width: calc(100% - 200px);
          }
        }
      }
    }
  }
}

.project__container--outer:hover {
  .project__url-sugar {
    opacity: 1;
  }
}
