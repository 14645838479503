.floatyParent {
    position: relative;

    @include breakpoint($main-breakpoint) {
        max-width: calc(100% - (#{$base-spacing-unit} * 2));
        margin: 0 auto 40px;
    }

    @include breakpoint-min($main-breakpoint) {
        opacity: 0;
        transform: translateY(25%);
        transition: all $transition-custom-slow 0.5s;

        &.floaty--active {
            opacity: 1;
            transform: translateY(0);
        }
    }

    span {
        display: block;
    }
}
.floatyChild {
    position: relative;
    display: inline-block;
    transition: all 0.25s ease;
}

.floatyRef {
    top: 50%;
    position: absolute;

    .ref-top-fix & {
        top: 0;
    }
}
