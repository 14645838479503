// Globally styled elements

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background: $white;
  overflow: hidden;
  text-align: center;
  font-style: normal;
  font-weight: 300;
  font-family: $base-font-family;
  text-rendering: optimizeLegibility;
  @include font-size($h5-font-size, $h5-line-height);
}

a {
  transition: all $transition-fast;
}

.em,
.em-svg {
  width: 1.75rem;
  height: 1.75rem;
  margin: 0 ($base-spacing-unit/2);
}

small {
  span {
    display: inline-block;
    vertical-align: middle;
  }
}

img {
  display: block;
  max-width: 100%;
}

.page-outer {
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
}

.page-inner {
  height: 100vh;
  background: $white;
  position: relative;
  overflow-y: scroll;

  .fade-exit,
  .fade-enter,
  .fade-enter-active {
    height: auto;
  }

  .fade-exit-active {
    z-index: 100;
    height: 100vh;
    position: relative;
  }
}
