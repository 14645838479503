.media-wrap {
  width: 100%;
  position: relative;

  @include margin-leader(1);
  @include margin-trailer(2);

  img,
  video,
  iframe {
    display: block;
    max-width: 100%;
  }

  &.offset--one {
    @include breakpoint-min($main-breakpoint) {
      margin-top: ($base-spacing-unit * 4);
    }
  }

  &.offset--minus-one {
    @include breakpoint-min($main-breakpoint) {
      margin-top: -($base-spacing-unit * 4);
    }
  }

  .project__alternating & {
    @include breakpoint-min($main-breakpoint) {
      @include margin-trailer(4);
    }
  }

  &.panel--transforms {
    opacity: 0;
    transform: translateY(25%);
    transition: all $transition-custom-slow 0.25s;

    &.panel--active {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
