// Mobile menu

.menu-mobile__inner {
  width: 100%;
  min-height: 100%;
  display: inline-block;
  padding: $base-spacing-unit;

  .menu-mobile-active--fixed & {
    min-height: 101%;
  }
  @include breakpoint-min($main-breakpoint) {
    padding: $base-spacing-unit ($base-spacing-unit * 2);
  }
}

.menu-mobile {
  @include z-index(5, fixed);
  opacity: 0;
  top: $header-height--mobile;
  left: 0;
  height: 0;
  width: 100%;
  overflow: hidden;
  background: $white;
  border-top: 1px solid $color-primary;
  transition: opacity $transition-fast, transform $transition-fast,
    top $transition-fast;
  @include breakpoint-min($main-breakpoint) {
    top: $header-height--desktop;
  }

  .menu-mobile-transitioning &,
  .menu-mobile-active & {
    backface-visibility: hidden;
    height: 100%;

    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.site-header--scrolled {
  .menu-mobile {
    top: $header-height--mobile;
  }
}

.menu-mobile-active .menu-mobile {
  opacity: 1;
}

.site-nav-mobile__menu {
  @extend %plain-list;

  .menu-link {
    color: $white;
    cursor: pointer;
    display: block;
    font-weight: 400;
    padding: ($base-spacing-unit / 4) ($base-spacing-unit / 1.75);
  }

  .menu-item {
    padding: ($base-spacing-unit / 2) 0;

    .menu-link {
      font-size: $h4-font-size;
      text-transform: uppercase;
    }
  }
}
