// Branding

.site-branding {
  width: $logo-width/2;
  left: ($base-spacing-unit);

  @include breakpoint-min($bp-sm) {
    width: $logo-width;
    left: ($base-spacing-unit * 2);
  }
}

.site-branding__name {
  @include element-invisible;
}

.site-branding__logo {
  path {
    fill: $color-primary;
    transition: all $transition-custom;
  }

  &:hover path {
    fill: $color-secondary;
  }
}

.site-branding__link {
  display: block;
}

.theme--light .site-branding__logo {
  path {
    fill: $white;
  }

  &:hover path {
    fill: $color-secondary;
  }
}
