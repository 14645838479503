// Header

.site-header {
  @include z-index(100, fixed);
  top: 0;
  height: 0;
  width: 100%;
}

.site-branding,
.site-navigation {
  @include z-index(100, fixed);
  top: 40px;
}
