// Articles

article {
  margin: 0;
  padding: 0;

  .home & {
    margin: $base-spacing-unit 0;
    padding: $base-spacing-unit 0;
  }

  .work & {
    margin: $base-spacing-unit 0;
    padding: $base-spacing-unit 0;
  }

  .project & {
    margin: $base-spacing-unit 0;
    padding: $base-spacing-unit 0;

    @include breakpoint-min($bp-xl) {
      margin: ($section-spacing-unit/2) 0;
      padding: ($section-spacing-unit/2) 0;
    }
  }

  @include breakpoint-min($bp-xl) {
    margin: ($section-spacing-unit/2) 0;
    padding: ($section-spacing-unit/2) 0;
  }

  &.project__navigation {
    @include breakpoint($bp-sm) {
      margin: 0;
      padding: ($base-spacing-unit) 0 0;
    }
  }

  .article__title,
  .article__paragraph {
    text-align: left;
  }

  .article__title {
    color: $color-primary;

    &.alternate--title {
      @include space(1);
      @include font-size(14px);
      text-transform: uppercase;
      font-family: $base-font-family;
    }
  }

  .article__paragraph {
    @include font-size(18px);
    @include margin-trailer(2);
    line-height: 1.75;
    color: $color-primary;
  }

  @include breakpoint($main-breakpoint) {

    .article__title,
    .article__paragraph {
      padding-left: $base-spacing-unit;
      padding-right: $base-spacing-unit;
    }
  }

  &.project__panel {
    @include breakpoint($main-breakpoint) {
      padding: ($base-spacing-unit * 2) 0;

      img {
        max-width: 100% !important;
      }
    }

    .article__title,
    .article__paragraph {
      text-align: center;
    }
  }

  &.project__intro {
    &.has-window {
      @include breakpoint-min($bp-xl) {
        @include margin-trailer(0);
        @include padding-trailer(0);
      }

      +.project__panel {
        @include breakpoint-min($bp-xl) {
          @include padding-leader(0);
        }
      }
    }
  }

  &.project__alternating {
    @include breakpoint-min($main-breakpoint) {
      @include padding-leader(5);

      .alternating-row {
        position: relative;
        padding: ($base-spacing-unit*5) 0;

        &:before {
          content: "";
          top: 0;
          left: 0;
          bottom: 0;
          width: 100%;
          display: block;
          position: absolute;
          background: $lighter-grey;
        }

        &:first-of-type {
          &:before {
            top: $base-spacing-unit * 5;
          }
        }

        &:nth-of-type(2) {
          &:before {
            bottom: $base-spacing-unit * 13;
          }
        }

        &:last-of-type {
          @include padding-trailer(0);

          &:before {
            display: none;
          }

          .grid .grid__item:nth-of-type(2) .media-wrap {
            @include margin-trailer(0);
          }
        }
      }
    }
  }
}
