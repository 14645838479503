// Sections

section {
  padding: $section-spacing-unit 0;

  .work & {
    padding: 0;

    &.featured {
      @include padding-leader(4);
      @include padding-trailer(6);

      @include breakpoint-min($bp-xl) {
        @include padding-leader(6);
        @include padding-trailer(6);
      }
    }
  }

  .about & {
    padding: 0;
    text-align: left;

    &.featured {
      @include padding-leader(6.75);
      @include padding-trailer(6);

      @include breakpoint-min($bp-xl) {
        @include padding-leader(10.5);
        @include padding-trailer(6);
      }
    }
  }
}
