// Navigation

.site-navigation {
  right: 0;

  @include breakpoint-min($main-breakpoint) {
    right: ($base-spacing-unit * 1.5);
  }
}

.site-navigation__menu {
  @extend %plain-list;
}

.site-navigation__menu-item {
  display: inline-block;
  font-size: $small-font-size;
  margin: 0 ($base-spacing-unit / 2);

  @include breakpoint-min($main-breakpoint) {
    font-size: $h5-font-size;
    margin: 0 ($base-spacing-unit * 2);
  }
}

.site-navigation__menu-link {
  display: block;
  position: relative;
  color: $color-primary;
  padding: ($base-spacing-unit / 2);
  transition: color $transition-custom;

  &.current {
    &:after {
      content: "";
      height: 1px;
      display: block;
      position: absolute;
      bottom: $base-spacing-unit / 2;
      background-color: currentColor;
      left: calc((#{$base-spacing-unit} * 1.2) / 2);
      width: calc(100% - (#{$base-spacing-unit} * 1.2));

      @include breakpoint-min($main-breakpoint) {
        height: 2px;
      }
    }
  }
}

.theme--light {
  .site-navigation__menu-link {
    color: $white;
    transition: color $transition-custom;

    &:hover {
      color: $color-secondary;
    }
  }
}
