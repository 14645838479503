/*

  Typography

*/

//----------------------------//

html {
  color: $body-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height / $base-font-size;
  text-rendering: optimizeLegibility;
}

*::selection {
  background: $color-secondary;
  color: $white;
}

// Attach a JSON object of media query values to be made available to JS
head {
  font-family: $bp-array;
}

[data-target] {
  cursor: pointer;
}

/**
 * $LINKS
 */
a,
i {
  -webkit-tap-highlight-color: transparent;
}

a {
  color: $color-primary;
  text-decoration: none;

  &:hover {
    color: $color-secondary;
  }

  //remove the dotted outline on firefox
  &:focus,
  &:active {
    outline: none;
  }
}

p,
blockquote,
pre,
dl {
  margin-top: 0;
  @include margin-trailer(1.5);
}

small {
  display: inline-block;
  @include margin-trailer(1);
}

/**
 * $QUOTES
 */
blockquote {
  @include font-size($base-font-size * 1.25, $base-line-height * 1.25);
  border-left: 2px solid $color-secondary;
  margin-left: 0;
  padding: 0 1.5em;
  text-align: left;

  cite {
    font-size: 0.75em;
    font-style: italic;
    margin: 0;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}

/**
 * Line Break
 */

hr {
  border: none;
  border-top: 2px solid $light-grey;
  margin: $base-spacing-unit * 8 0;
  @include breakpoint($main-breakpoint) {
    margin: $base-spacing-unit * 2 0;
  }
}

q {
  font-style: italic;
}

pre {
  border: 1px solid $grey;
  border-radius: $base-border-radius;
  padding: $base-spacing-unit;
}

pre,
code {
  font-family: "courier new", monospace;
  overflow: auto;
}

/**
 * $HEADINGS
 */

%heading-style {
  @include margin-trailer(1);
  font-family: $heading-font-family;
  font-weight: 400;
  margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.alpha,
.beta,
.gamma,
.delta,
.epsilon,
.zeta {
  @extend %heading-style;
}

// Using a Major Third Scale
// Change the multipliers to create your own scale that suits the project
h1,
.alpha {
  @include font-size($h1-font-size, $h1-line-height);
}

h2,
.beta {
  @include font-size($h2-font-size, $h2-line-height);
}

h3,
.gamma {
  @include font-size($h3-font-size, $h3-line-height);
}

h4,
.delta {
  @include font-size($h4-font-size, $h4-line-height);
}

h5,
.epsilon {
  @include font-size($h5-font-size, $h5-line-height);
}

h6,
.zeta {
  @include font-size($h6-font-size, $h6-line-height);
}

/**
 * The `.lead` class is used to make the introductory text (usually a paragraph)
 * of a document slightly larger.
 */
.lead,
%lead {
  @include font-size($h3-font-size, $h3-line-height);
}

.purple {
  color: $color-tertiary;
}

.blue {
  color: $color-quaternary;
}
