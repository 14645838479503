.about {
  .image-wrap {
    position: relative;
    display: inline-block;
    @include margin-trailer(2);

    .image {
      @include z-index(1, relative);

      &.blurs {
        @include z-index(0, absolute);
        top: 15%;
        left: -45%;
        max-width: 150%;
      }
    }

    i.em {
      @include z-index(2, absolute);

      &.sparkles {
        top: 12.255556%;
        right: -6.125556%;
        width: ($section-spacing-unit/1.5);
        height: ($section-spacing-unit/1.5);

        @include breakpoint-min($main-breakpoint) {
          width: ($section-spacing-unit);
          height: ($section-spacing-unit);
        }
      }

      &.wave {
        left: -6.125556%;
        bottom: 12.255556%;
        width: ($section-spacing-unit);
        height: ($section-spacing-unit);

        @include breakpoint-min($main-breakpoint) {
          width: ($section-spacing-unit * 1.5);
          height: ($section-spacing-unit * 1.5);
        }
      }
    }
  }

  .content-wrap,
  .experience-wrap {
    @include margin-leader(2);
  }

  .content-wrap {
    p {
      @include font-size(22px);
      line-height: 1.8;
    }
  }

  .experience-wrap {
    h2 {
      @include font-size(20px);
      @include margin-trailer(0.5);
      font-weight: 400;
    }

    small {
      @include font-size(14px);
      @include margin-trailer(0.25);
      display: block;
      line-height: 1.5;
    }

    ul {
      @include margin-leader(0);
      @include margin-trailer(0);
      @include font-size(10px);
      padding-left: 15px;
      text-transform: uppercase;

      small {
        @include space(1);
        @include font-size(10px);
      }
    }

    .experience__date {
      @include space(1);
      @include font-size(10px);
      @include margin-trailer(1);

      display: block;
      color: $color-senary;
      text-transform: uppercase;
    }
  }
}
