// Mobile menu button

.menu-mobile__btn--toggle {
  width: 12px;
  margin: 0 auto;
  position: relative;
  height: $base-spacing-unit;

  span {
    display: block;
    position: absolute;
    background: $color-primary;
    height: ($base-spacing-unit / 10);
    margin-bottom: ($base-spacing-unit / 10);

    top: 0;
    width: 100%;
    transform-origin: center center;
    transition: all $transition-fast;

    &:nth-of-type(1) {
      top: ($base-spacing-unit * 0.2);
    }

    &:nth-of-type(2) {
      left: 50%;
      transform: translateX(-50%);
      top: ($base-spacing-unit * 0.4);
    }

    &:nth-of-type(3) {
      top: ($base-spacing-unit * 0.6);
    }
  }
}

.menu-mobile__btn--text {
  @extend %element-invisible;
}

.menu-mobile__btn {
  cursor: pointer;
  top: 50%;
  left: 20px;
  padding: 0;
  width: 22px;
  height: 22px;
  display: block;
  overflow: hidden;
  background: none;
  position: absolute;
  border-radius: 50%;
  text-decoration: none;
  border: 2px solid $color-primary;
  transform: translateY(-50%);

  .menu-mobile-active & {
    color: $color-secondary;
    border-color: $color-secondary;

    .menu-mobile__btn--toggle {
      span {
        background: $color-secondary;

        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3) {
          top: ($base-spacing-unit * 0.4);
        }

        &:nth-of-type(1) {
          transform: rotate(-45deg);
        }

        &:nth-of-type(2) {
          opacity: 0;
          width: 0;
        }

        &:nth-of-type(3) {
          transform: rotate(45deg);
        }
      }
    }
  }
}
@include breakpoint($main-breakpoint) {
  .menu-mobile__btn {
    display: block;
  }
}
