.overlap-blocks {
    position: relative;
    @include breakpoint-min($main-breakpoint) {
        &.offset-20 {
            @include margin-leader(2);
        }
        &.offset-40 {
            @include margin-leader(4);
        }
        &.offset-60 {
            @include margin-leader(6);
        }
    }

    .overlap-block {
        @include z-index(1, relative);
        @include breakpoint-min($main-breakpoint) {
            width: 55%;

            &.over {
                @include z-index(2, absolute);
                transform: translateY(50%);
                box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
            }

            &.top {
                top: 0;
            }

            &.left {
                left: 0;
            }

            &.right {
                right: 0;
            }

            &.bottom {
                bottom: 0;
            }
        }
    }
}
