/*

    Print

*/
@media only print {
  // ADD PRINT STYLES

  // document
  @page {
    margin: 0.5cm;
  }
}
