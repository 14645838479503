/*

    Buttons

*/

/* Base Button Typography */
%btn-typography {
  @include space(1);
  text-align: center;
  font-family: inherit;
  text-decoration: none;
  text-transform: uppercase;
  font-size: $small-font-size;
  line-height: $btn-line-height;
  font-weight: $font-weight-thin;
}

/* Base Button Appearance */
%btn-appearance {
  margin: 0;
  border: 0;
  color: $white;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  background-color: $color-primary;
  background: linear-gradient(90deg,
      rgba(240, 50, 115, 1) 0%,
      rgba(250, 110, 85, 1) 50%,
      rgba(255, 165, 55, 1) 100%);
  transition: all $transition-fast;
  -webkit-tap-highlight-color: transparent;
  padding: $btn-default-padding ($btn-default-padding * 2);
}

%btn-appearance--negative {
  background: transparent;
  color: $btn-secondary;
}

%btn-appearance--hover {
  // box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
}

.btn {
  @extend %btn-typography;
  @extend %btn-appearance;
}

.btn--negative {
  @extend .btn;
  @extend %btn-appearance--negative;
}

.btn:not(.btn--negative):active,
.btn:not(.btn--negative):hover {
  // @extend %btn-appearance--hover;
  color: $white;
  animation: button-blend2 2s ease-in-out infinite alternate;
}

.btn:focus {
  outline: none;
  // box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
}

.btn--disabled,
.btn--disabled:hover,
.btn--disabled:active,
.btn--disabled:focus {
  color: $grey;
  cursor: default;
  box-shadow: none;
  background-color: $light-grey;
}
