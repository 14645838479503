/*

  Flexbox Grid

  */

/* ================================================= */
/* Helpers - Variables, Mixins, Extenders, Functions */

// Variables
$grid-columns: 12;
$base-class: "col";
$gutter-thin: $base-spacing-unit * 0.5; // 20px gutter
$gutter-medium: $base-spacing-unit * 0.75; // 30px gutter
$gutter-norm: $base-spacing-unit * 1; // 40px gutter
$gutter-wide: $base-spacing-unit * 1.25; // 50px gutter

$breakpoints: (
  sm: $bp-sm,
  xm: $bp-md,
  md: $bp-lg,
  lg: $bp-xl,
);

// Extenders
// - Base Styles
%grid {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
}

// Mixins
// - Gutters
@mixin define-gutters($spacing) {
  margin-left: -$spacing;
  margin-right: -$spacing;

  .grid__item {
    padding-left: $spacing;
    padding-right: $spacing;
  }
}
@mixin define-gutter-bpclasses($key) {
  &.#{$key}-gutter--thin {
    @include define-gutters($gutter-thin);
  }

  &.#{$key}-gutter--medium {
    @include define-gutters($gutter-medium);
  }

  &.#{$key}-gutter--default {
    @include define-gutters($gutter-norm);
  }

  &.#{$key}-gutter--wide {
    @include define-gutters($gutter-wide);
  }
}
@mixin define-justify($key) {
  &.#{$key}-just--start {
    justify-content: flex-start;
  }

  &.#{$key}-just--end {
    justify-content: flex-end;
  }

  &.#{$key}-just--center {
    justify-content: center;
  }

  &.#{$key}-just--around {
    justify-content: space-around;
  }

  &.#{$key}-just--between {
    justify-content: space-between;
  }
}
@mixin define-alignment($key) {
  &.#{$key}-align--top {
    align-items: flex-start;
  }

  &.#{$key}-align--middle {
    align-items: center;
  }

  &.#{$key}-align--bottom {
    align-items: flex-end;
  }
}
@mixin define-order($key) {
  &.#{$key}-first {
    order: 1;
  }

  &.#{$key}-last {
    order: 2;
  }
}

/* ================================================= */

/* Base grid styles */

// Functions
// - Generate default (mobile) columns
.grid__item {
  @for $i from 1 through $grid-columns {
    &.#{$base-class}-#{$i} {
      width: percentage($i / $grid-columns);
    }
  }
  @for $i from 0 through $grid-columns {
    &.#{$base-class}-offset-#{$i} {
      margin-left: percentage($i / $grid-columns);
    }
  }
}

.grid {
  // Base styles, used for all breakpoints
  @extend %grid;

  // Using `--base-` as the mobile-first class
  // Define justification, alignment, and breakpoint classes
  @include define-justify($base-class);
  @include define-alignment($base-class);
  @include define-gutter-bpclasses($base-class);

  // Define gutters
  @include define-gutters($gutter-norm);

  &.grid--reverse {
    flex-direction: row-reverse;
  }

  &.grid--flush {
    @include define-gutters(0);
  }

  .grid__item {
    flex: 0 0 auto;
    @include define-order($base-class);
  }
}

// - Generate scoped columns for other breakpoints
@each $key, $value in $breakpoints {
  @if $value {
    @media only screen and (min-width: #{$value + 1px}) {
      .grid {
        @include define-justify(#{$key});
        @include define-alignment(#{$key});
        @include define-gutter-bpclasses(#{$key});
      }

      .grid__item {
        @include define-order(#{$key});
        @for $i from 1 through $grid-columns {
          &.#{$key}-#{$i} {
            width: percentage($i / $grid-columns);
          }
        }
        @for $i from 0 through $grid-columns {
          &.#{$key}-offset-#{$i} {
            margin-left: percentage($i / $grid-columns);
          }
        }
      }
    }
  }
}
