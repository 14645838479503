// Project list

.project-list {
  @extend .container;
  @extend .container--large;

  .headline {
    @include space(1);
    @include margin-trailer(0);
    text-transform: uppercase;
    font-family: $base-font-family;

    @include breakpoint($main-breakpoint) {
      @include font-size($h3-font-size, $h3-line-height);
    }

    .home & {
      @include font-size(20px);
    }
  }

  .project {
    opacity: 0;
    width: 100%;
    flex-basis: 100%;
    max-width: 1150px;
    position: relative;
    transform: translateY(25%);
    transition: all $transition-custom-slow;

    @include breakpoint-min($main-breakpoint) {
      width: 50%;
      flex-basis: 50%;
    }
  }

  .project[data-index="0"] {
    transition-delay: .25s;
  }

  .project[data-index="0"],
  .project[data-index="3"],
  .project[data-index="6"] {
    @include breakpoint-min($main-breakpoint) {
      width: 75%;
      flex-basis: 75%;
    }
  }

  .project[data-index="1"],
  .project[data-index="4"] {
    @include breakpoint-min($main-breakpoint) {
      margin-top: -($base-spacing-unit * 7.5);
    }
  }

  .project[data-index="2"],
  .project[data-index="5"] {
    transition-delay: .25s;

    @include breakpoint-min($main-breakpoint) {
      margin-top: ($base-spacing-unit * 7.5);
    }
  }

  .project-list__panel {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @include breakpoint-min($main-breakpoint) {
      padding: ($base-spacing-unit * 2) 0;
    }

    &.panel--active {
      .project {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
