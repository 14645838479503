/*

  Helpers

  Miscellaneous utility classes

*/

// CLEARFIX

%clearfix,
%cf,
.clearfix,
.cf {

    &::after {
        clear: both;
        content: "";
        display: table;
    }
}

/* -------------------------------------- */

// SPACING

.trailer--single {
    @include margin-trailer(1);
}

.trailer--double {
    @include margin-trailer(2);
}

.trailer--half {
    @include margin-trailer(0.5);
}

.trailer--none {
    @include margin-trailer(0);
}

/* -------------------------------------- */

// LISTS

.hlist,
%hlist {
    margin-bottom: 0;
    padding-left: 0;

    > li {
        display: inline-block;
    }
}

%plain-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

/* -------------------------------------- */

// FULL COVER

%full-cover {
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
}

/* -------------------------------------- */

// VISIBILITY

%text-invisible {
    @include squish-text;
}

%element-hidden,
.element-hidden {
    @include element-hidden;
}

.element-hidden--mobile {
    @include breakpoint($main-breakpoint) {
        @include element-hidden;
    }
}

.element-hidden--sm {
    @include breakpoint($bp-sm) {
        @include element-hidden;
    }
}

.element-hidden--md {
    @include breakpoint($bp-md) {
        @include element-hidden;
    }
}

.element-hidden--desk {
    @include breakpoint-min($main-breakpoint) {
        @include element-hidden;
    }
}

%element-invisible,
%sr-only,
.sr-only {
    @include element-invisible;
}

%element-visible {
    @include element-visible;
}

%element-focusable {
    @include element-focusable;
}
